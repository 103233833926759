import request from '../../utils/axios';

// 技师端接口
// 测试获取案件链接
export function getLink(params) {
    return request({
        url: '/h5/order/test',
        method: 'get',
        params: params
    })
}
// 案件详情
export function orderDetail(params) {
    return request({
        url: '/h5/order/detail',
        method: 'get',
        params: params
    })
}
// 技师接单
export function driverAccept(data) {
    return request({
        url: '/h5/driver/order/accept',
        method: 'post',
        data: data
    })
}
// 技师电话车主
export function callContact(data) {
    return request({
        url: '/h5/driver/order/contact',
        method: 'post',
        data: data
    })
}
// 效验车主手机号后四位
export function validMobile(data) {
    return request({
        url: '/h5/driver/order/mobile',
        method: 'post',
        data: data
    })
}
// 到达救援地
export function arrivestart(data) {
    return request({
        url: '/h5/driver/order/arrivestart',
        method: 'post',
        data: data
    })
}
// 上传救援服务照片
export function driverUploadPic(data) {
    return request({
        url: '/h5/driver/order/picture',
        method: 'post',
        data: data
    })
}
// 前往目的地
export function toend(data) {
    return request({
        url: '/h5/driver/order/toend',
        method: 'post',
        data: data
    })
}
// 到达目的地
export function arriveend(data) {
    return request({
        url: '/h5/driver/order/arriveend',
        method: 'post',
        data: data
    })
}
// 工单确认
export function confirmWork(data) {
    return request({
        url: '/h5/driver/order/confirm',
        method: 'post',
        data: data
    })
}
// 服务完成
export function orderFinish(data) {
    return request({
        url: '/h5/driver/order/finish',
        method: 'post',
        data: data
    })
}
// 客户端接口
// 车主修改地址
export function saveLocation(data) {
    return request({
        url: '/h5/owner/order/save',
        method: 'post',
        data: data
    })
}
// 上传现场照片
export function uploadPicture(data) {
    return request({
        url: '/h5/owner/order/picture',
        method: 'post',
        data: data
    })
}
export function licenseOCR(data) {
    return request({
        url: '/h5/ocr/license',
        method: 'post',
        data: data
    })
}
// 客户信息 水印配置
export function getCustomerInfo(params) {
    return request({
        url: '/v2/param/customer/info',
        method: 'get',
        params
    })
}
// 聚合技师信息
export function dispatchDriverInfo(params) {
    return request({
        url: '/v2/order/driver',
        method: 'get',
        params
    })
}
// 订单备注信息
export function orderRemark(params) {
    return request({
        url: '/order/remark',
        method: 'get',
        params: params
    })
}
// 订单照片配置
export function orderPhotoConfig(params) {
    return request({
        url: '/v2/order/picture/param',
        method: 'get',
        params: params
    })
}
// 照片模板
export function pictureTemplate(params) {
    return request({
        url: '/v2/order/picture/template',
        method: 'get',
        params: params
    })
}
// 收款二维码
export function orderWxPay(data) {
    return request({
        url: '/h5/driver/order/pay',
        method: 'post',
        data: data
    })
}
// 支付状态查询
export function orderPayStatus(params) {
    return request({
        url: '/h5/driver/order/pay',
        method: 'get',
        params: params
    })
}
// 手机号检测
export function driverCheck(params) {
    return request({
        url: '/h5/driver/check',
        method: 'get',
        params: params
    })
}
// 技师信息维护
export function improveDriverInfo(data) {
    return request({
        url: '/h5/driver/info',
        method: 'post',
        data
    })
}