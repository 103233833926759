
function formatNumber(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
}
// 时间戳转日期 可传格式 默认 Y-M-D h:m:s
function formatTime(number, format) {
    if (!format) {
        format = 'Y-M-D h:m:s'
    }
    // console.log(format, 'format')
    let formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
    let returnArr = [];
    if ((typeof number === 'number') && (number.toString().length === 10)) {
        number = number * 1000
    }
    if (number.toString().length < 10){
        return number
    }
    let date = new Date(number);
    returnArr.push(date.getFullYear());
    returnArr.push(formatNumber(date.getMonth() + 1));
    returnArr.push(formatNumber(date.getDate()));

    returnArr.push(formatNumber(date.getHours()));
    returnArr.push(formatNumber(date.getMinutes()));
    returnArr.push(formatNumber(date.getSeconds()));

    for (let i in returnArr) {
        format = format.replace(formateArr[i], returnArr[i]);
    }
    return format;
}
// 是否是微信环境
function isWeiXin() {
    let ua = window.navigator.userAgent.toLowerCase();
    return ua.indexOf("micromessenger") != -1;
}
// 是否是safari浏览器
function isSafari() {
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('applewebkit') > -1 && ua.indexOf('mobile') > -1 && ua.indexOf('safari') > -1 &&
        ua.indexOf('linux') === -1 && ua.indexOf('android') === -1 && ua.indexOf('chrome') === -1 &&
        ua.indexOf('ios') === -1 && ua.indexOf('browser') === -1) {
        return true;
    } else {
        return false;
    }
}
// 截取链接参数
function getQueryString(name) {
    let query = window.location.href.split('?')[1];
    if(name){
        if(!query){
            return null
        }
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        let message = query.match(reg);
        if (message !== null) {
            return message[2];
        }
        return null
    }else{
        return query
    }

}
// 效验经纬度
function validLngLat(lnglat) {
    let longitude = lnglat.split(',')[0];
    let latitude = lnglat.split(',')[1];
    let lon = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/;
    let lat = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/;
    let lonRe = new RegExp(lon);
    let latRe = new RegExp(lat);
    if (longitude == 0 || latitude == 0 || !lonRe.test(longitude) || !latRe.test(latitude)){
        return false
    }else{
        return true
    }
}
// 将base64转换为blob
function dataURLtoFile(dataURI, filename) {
    var arr = dataURI.split(',')
    var mime = arr[0].match(/:(.*?);/)[1]
    var bstr = atob(arr[1])
    var n = bstr.length
    var u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    // 转换成file对象
    return new File([u8arr], filename, { type: mime })
}
// 高德定位
function getGeoLocation(){
    return new Promise((resolve, reject)=>{

        if(window.testlocaltion){
            resolve(window.testlocaltion);
            return
        }
        // let location = {
        //     address: '广东省惠州市惠城区惠环街道和畅西三路恒裕·世纪广场',
        //     city: '惠州市',
        //     district: '惠城区',
        //     lat: '22.12222',
        //     lng: '122.22222',
        //     province: '广东省'
        // }
        // resolve(location);
        // return

        AMap.plugin('AMap.Geolocation', function () {
            let geolocation = new AMap.Geolocation({
                enableHighAccuracy: true,//是否使用高精度定位，默认:true
                GeoLocationFirst: true,     //pc端有限浏览器定位
                timeout: 60000,
                noIpLocate: 1,  //是否禁止使用IP定位,默认值为0,可选值 0~3; 0可以使用IP定位,1手机设备禁止使用IP定位,2PC上禁止使用IP定位,3所有设备禁止使用IP定位
                showButton: false,
                maximunAge: 10000
            })
            geolocation.getCurrentPosition(function (status, result) {
                if (status == 'complete') {
                    let lng = result.position.lng;
                    let lat = result.position.lat;
                    let lnglat = [lng, lat]
                    let geocoder = new AMap.Geocoder()
                    geocoder.getAddress(lnglat, function(status2, result2) {
                        if (status2 === 'complete' && result2.info === 'OK') {
                            // result为对应的地理位置详细信息
                            let regeocode = result2.regeocode;
                            let address = regeocode.formattedAddress;
                            let province = regeocode.addressComponent.province;
                            let city = regeocode.addressComponent.city;
                            let district = regeocode.addressComponent.district;
                            let location = {
                                address: address,
                                city: city,
                                district: district,
                                lat: lat,
                                lng: lng,
                                province: province
                            }
                            resolve(location);
                        }else{
                            alert(`定位失败${result2.info || status2}`)
                            reject(result2.info || status2);
                        }
                    })
                } else {
                    if (result.info == 'NOT_SUPPORTED') {
                        alert('当前浏览器不支持定位功能！')
                        reject();
                    } else {
                        if (result.message == 'Get geolocation time out') {
                            alert('定位超时！')
                            reject();
                        }
                        if (result.message == 'Geolocation permission denied.') {
                            alert('未授权获取当前位置')
                            reject();
                            
                            // let location = {
                            //     address: 'address',
                            //     city: 'city',
                            //     district: 'district',
                            //     lat: '22.12222',
                            //     lng: '122.22222',
                            //     province: 'province'
                            // }
                            // resolve(location);
                        }else{
                            try{
                                // alert(JSON.stringify(result.message))
                            }catch(err){
                                
                            }

                            !window.ondebugger && (alert(`定位失败-${result.message || ''}`))
                            console.log(`定位失败-${result.message || ''}`)
                            // reject(result.message);
                            return
                            AMap.plugin("AMap.CitySearch", function () {
                                var citySearch = new AMap.CitySearch();
                                citySearch.getLocalCity(function (status, result) {
                                    if (status === "complete" && result.info === "OK") {
                                        // 查询成功，result即为当前所在城市信息
                                        console.log(result, 'IP定位')
                                        let position = result.bounds.getCenter();
                                        let lng = position.lng;
                                        let lat = position.lat;
                                        let address = result.province + result.city;
                                        let province = result.province;
                                        let city = result.city;
                                        let district = '';
                                        let location = {
                                            address: address,
                                            city: city,
                                            district: district,
                                            lat: lat,
                                            lng: lng,
                                            province: province
                                        }
                                        resolve(location);
                                    } else {
                                        reject('定位失败');
                                    }
                                });
                            });
                        }
                    }

                }
            });
        })
    })
}
function regularValue(str){
    // str = `【中华保险】新增查勘任务，现场，案件号224460093468车牌号粤AA08F63雷克萨斯LEXUSNX200越野车的车辆，车架号:123123123，事故号:545454555，于2022-06-0810:00:00在广东省广州市番禺区金业别墅花园因碰撞出险的调度已完成，多车现场联系人曾露，电话13560002425。请及时联系处理，监督服务电话：020-87513543，。`;
    // let reg_no = str.match(/((?<=案件号)|(?<=报案号)).\w*/);
    // let no = reg_no ? reg_no[0].match(/(?<=\W).\w*/) ? reg_no[0].match(/(?<=\W).\w*/)[0] : reg_no[0] : '';
    // console.log(no, '报案号');
    // let reg_accident = str.match(/(?<=事故号).\w*/);
    // let accident = reg_accident ? reg_accident[0].match(/(?<=\W).\w*/) ? reg_accident[0].match(/(?<=\W).\w*/)[0] : reg_accident[0] : '';
    // console.log(accident, '事故号');
    // let reg_vin = str.match(/(?<=车架号).\w*/);
    // let vin = reg_vin ? reg_vin[0].match(/(?<=\W).\w*/) ? reg_vin[0].match(/(?<=\W).\w*/)[0] : reg_vin[0] : '';
    // console.log(vin, '车架号');
    // let carNo = str.match(/[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1,2}/g);
    // carNo = carNo ? carNo[0] : '';
    // console.log(carNo, '车牌号');
    // let carType = str.match(/(?<=[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1,2}).*?(?=[,.，。的])/);
    // carType = carType ? carType[0] : '';
    // console.log(carType, '车牌号');
    // let phone = str.match(/(86[1][0-9]{10})|([1][0-9]{10})/g);
    // phone = phone ? phone[0] : '';
    // console.log(phone, '联系电话')
    // let name = str.match(/(?<=(报案人|联系人|现场联系人|车主)).*?(?=[,.，。 ])/);
    // name = name ? name[0] : ''
    // console.log(name, '姓名')
    if(!str){
        return false
    };
    // js截取
    let no_index = str.indexOf('案件号:') > 0 ? str.indexOf('案件号:') + 4 : false 
        || str.indexOf('案件号：') > 0 ? str.indexOf('案件号：') + 4 : false 
            || str.indexOf('案件号 ') > 0 ? str.indexOf('案件号 ') + 4 : false 
                || str.indexOf('案件号') > 0 ? str.indexOf('案件号') + 3 : false 
                    || str.indexOf('报案号:') > 0 ? str.indexOf('报案号：') + 4 : false 
                        || str.indexOf('报案号：') > 0 ? str.indexOf('报案号:') + 4 : false 
                            || str.indexOf('报案号 ') > 0 ? str.indexOf('报案号 ') + 4 : false 
                                || str.indexOf('报案号') > 0 ? str.indexOf('报案号') + 3 : false;
    let no_str = str.substring(no_index, str.length);
    let no = no_index ? no_str.match(/.\w*/) ? no_str.match(/.\w*/)[0] : '' : '';
    console.log(no, '报案号');

    let accident_index = str.indexOf('事故号:') > 0 ? str.indexOf('事故号:') + 4 : false
        || str.indexOf('事故号：') > 0 ? str.indexOf('事故号：') + 5 : false
            || str.indexOf('事故号 ') > 0 ? str.indexOf('事故号 ') + 4 : false
                || str.indexOf('事故号') > 0 ? str.indexOf('事故号') + 3 : false;
    let accident_str = str.substring(accident_index, str.length);
    let accident = accident_index ? accident_str.match(/.\w*/) ? accident_str.match(/.\w*/)[0] : '' : '';
    console.log(accident, '事故号');

    let vin_index = str.indexOf('车架号:') > 0 ? str.indexOf('车架号:') + 4 : false
        || str.indexOf('车架号：') > 0 ? str.indexOf('车架号：') + 5 : false
            || str.indexOf('车架号 ') > 0 ? str.indexOf('车架号 ') + 4 : false
                || str.indexOf('车架号') > 0 ? str.indexOf('车架号') + 3 : false;
    let vin_str = str.substring(vin_index, str.length);
    let vin = vin_index ? vin_str.match(/.\w*/) ? vin_str.match(/.\w*/)[0] : '' : '';
    console.log(vin, '车架号');

    let carNo = str.match(/[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1,2}/g);
    carNo = carNo ? carNo[0] : '';
    console.log(carNo, '车牌号');
    
    let car_model = '';
    if(carNo){
        let carType_index = str.indexOf(carNo) + carNo.length;
        let carType_str = str.substring(carType_index, str.length);
        let carType_index_end1 = carType_str.indexOf('的');
        let carType_index_end2 = carType_str.indexOf('，');
        let carType_index_end3 = carType_str.indexOf('。');
        let carType_index_end4 = carType_str.indexOf(',');
        let carType_index_end5 = carType_str.indexOf('.');
        let carTyp_arr = [carType_index_end1, carType_index_end2, carType_index_end3, carType_index_end4, carType_index_end5];
        let carType_min = mathMin(carTyp_arr);
        car_model = carType_min ? carType_str.substring(0, carType_min) : '';
    };
    console.log(car_model, '品牌型号');

    // 报案人|联系人|现场联系人|车主
    let name_index = str.indexOf('报案人') > 0 ? str.indexOf('报案人') + 3 : false
        || str.indexOf('联系人') > 0 ? str.indexOf('联系人') + 3 : false
            || str.indexOf('现场联系人') > 0 ? str.indexOf('现场联系人') + 5 : false
                || str.indexOf('车主') > 0 ? str.indexOf('车主') + 2 : false;
    let name = '';
    if (name_index){
        let name_str = str.substring(name_index, str.length);
        let name_index_end1 = name_str.indexOf('，');
        let name_index_end2 = name_str.indexOf('。');
        let name_index_end3 = name_str.indexOf(',');
        let name_index_end4 = name_str.indexOf('.');
        let name_index_end5 = name_str.indexOf(' ');
        let name_arr = [name_index_end1, name_index_end2, name_index_end3, name_index_end4, name_index_end5];
        let name_min = mathMin(name_arr);
        name = name_min ? name_str.substring(0, name_min) : '';
    }
    console.log(name, '车主');

    let phone = str.match(/(86[1][0-9]{10})|([1][0-9]{10})/g);
    phone = phone ? phone[0] : '';
    console.log(phone, '联系电话');
    if (no || accident || vin || carNo || car_model || name || phone){
        let info = {
            no,
            accident,
            vin,
            carNo,
            car_model,
            name,
            phone
        };
        return info
    }else{
        return false
    }
};
// 最小值
function mathMin(arr) {
    var min = arr[0];
    for (var i = 0; i < arr.length; i++) {
        if (arr[i] > 0 && arr[i] < min) {
            min = arr[i];
        }
    }
    return min > 0 ? min : '';
};
// 获取设备型号
function getMobileModel(md, os, userAgent) {
    let model = ''
    if (userAgent.split(")")[0].includes("HMSCore")) {
        // 获取华为鸿蒙
        let HMS = userAgent.split(")")[0].split("HMSCore")[0].split(";");
        model = HMS[HMS.length - 2];
    } else if (os == "iOS") {
        //ios系统的处理
        // @ts-ignore
        model = md.mobile();
        if (model == "iPhone") {
            let iphoneArr = [
                ["430,932,3", "A15", "iPhone 14 Pro Max"],
                ["393,852,3", "A15", "iPhone 14 Pro"],
                ["375,812,3", "A15", "iPhone 13(12) min"],
                ["428,926,3", "A14", "iPhone 14(12/13) Plus"],
                ["390,844,3", "A14", "iPhone 14(12/13)"],
                ["414,896,3", "A13", "iPhone 11 Pro Max(Xs Max)"],
                ["375,812,3", "A13", "iPhone 11 Pro(X/Xs)"],
                ["414,896,2", "A13", "iPhone 11(XR)"],
                ["414,736,3", "A12", "iPhone 8(7/6s) Plus"],
                ["375,667,2", "A11", "iPhone 8(7/6)"],
                ["320,568,2", "A11", "iPhone 5(s/se)"],
                ["320,480,2", "A10", "iPhone 4(s)"],
            ];

            // 获取GPU信息
            var canvas = document.createElement("canvas"),
                gl = canvas.getContext("experimental-webgl"),
                // @ts-ignore
                debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
            // @ts-ignore

            let iphoneGPU = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
            let width = window.screen.width;
            let height = window.screen.height;
            let devicePixelRatio = window.devicePixelRatio;
            console.log(width, height, devicePixelRatio);

            let baseInfo = width + "," + height + "," + devicePixelRatio;
            iphoneArr.some((item) => {
                if (item[0] === baseInfo) {
                    model = item[2];
                    console.log("iphoneGPU", iphoneGPU == item[1]);
                }
            });
        } else {
            model = "iPad";
        }
    } else if (os == "AndroidOS") {
        //Android系统的处理
        var j;
        var sss = userAgent.split(";");
        for (var i = 0; i < sss.length; i++) {
            if (sss[i].indexOf("Build/") > 0) {
                j = i;
                break;
            }
        }
        // @ts-ignore
        if (j > -1) {
            // @ts-ignore
            // try{
            //     model = sss[j].slice(sss[j].indexOf("Build/")+6, sss[j].indexOf(")"))
            // }catch(err){
                model = sss[j].substring(0, sss[j].indexOf("Build/"));
            // }
        }
    }
    return model;
};
module.exports = {
    formatTime,     //时间戳转日期
    isWeiXin,       //是否是微信环境
    isSafari,       //是否是safari浏览器
    getQueryString,
    validLngLat,        //效验经纬度
    dataURLtoFile,
    getGeoLocation,
    regularValue,
    getMobileModel
}
