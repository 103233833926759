import request from '../../utils/axios';

// 客户价格参数
export function priceConfig(params){
    return request({
        url: '/v2/param/customer/price',
        method: 'get',
        params: params
    })
}
// 客户参数
export function customerParameter(params) {
    return request({
        url: '/backend/customer/login',
        method: 'get',
        params: params
    })
}
// 客户登录
export function login(data){
    return request({
        url: '/backend/customer/login',
        method: 'post',
        data: data
    })
}
// 案件下单 
export function createOrder(data){
    return request({
        url: '/h5/customer/order/create',
        method: 'post',
        data: data
    })
}
// 上传现场照片到阿里云
export function uploadFileOss(url,data) {
    return request({
        url: url,
        method: 'post',
        data: data
    })
}
// 阿里云参数
export function ossParams(params) {
    return request({
        url: '/public/upload',
        method: 'get',
        params: params
    })
}
// 客户信息
export function customerInfo(params) {
    return request({
        url: '/v2/general/customer/info',
        method: 'get',
        params: params
    })
}
// 案件列表
export function orderList(params) {
    return request({
        url: '/h5/customer/order',
        method: 'get',
        params: params
    })
}
// 免密案件筛选
export function noTokenOrderList(params) {
    return request({
        url: 'h5/customer/order/list',
        method: 'get',
        params: params
    })
}
// 订单详情
export function orderDetail(params) {
    return request({
        url: '/h5/customer/order/detail',
        method: 'get',
        params: params
    })
}
// 获取报价信息
export function getPriceConfig(params) {
    return request({
        url: '/h5/customer/order/param',
        method: 'get',
        params: params
    })
}
// 案件价格计算
export function computePrice(data) {
    return request({
        url: '/h5/customer/order/compute',
        method: 'post',
        data: data
    })
}
// 案件支付
export function orderPay(data) {
    return request({
        url: '/h5/customer/order/pay',
        method: 'post',
        data: data
    })
}
// 支付状态
export function orderPayStatus(params) {
    return request({
        url: '/h5/customer/order/pay',
        method: 'get',
        params: params
    })
}
// 救援项目配置
export function serviceConfigList(params) {
    return request({
        url: '/v2/param/service',
        method: 'GET',
        params
    })
}
// 案件评价反馈
export function orderEvaluate(params) {
    return request({
        url: '/h5/order/evaluate',
        method: 'POST',
        params
    })
}